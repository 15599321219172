// -------------- THEME SWITCHER -------------- //
@mixin dark-appearance {
  filter: invert(1);
  img {
    filter: invert(1);

    &.ioda { filter: invert(0); }
  }
}

body[a="dark"] { @include dark-appearance; }


@media (prefers-color-scheme: dark) {
  body[a="auto"] { @include dark-appearance; }
}
// -------------------------------------------- //

// bg color is also needed in html in order to
// block body's background propagation
// see: https://stackoverflow.com/a/61265706
html, body { background: white; }

html { height: 100%; }

body {
  color: black;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  min-height: 100%;
  overflow-wrap: break-word;
}

footer {
}

.post-meta { text-align: right; margin: 0; }
.post-meta+.tags { font-size: 80%; }
#profile { float: right; padding: 1rem; }

h3, h4, h5, h6 { margin-top: 3rem; }

hr { margin: 2rem 0; }

p { margin: 1rem 0; }

a { text-decoration: none; }

nav { float: right; padding-bottom: 1.5rem }
nav ul { list-style: none; padding: 0; margin: 0; }
nav li { display: block; float: left; padding-left: 0.5rem; }
nav ul li::before { content: "/ "; }

ul { margin: 0; padding: 1rem; }
li { margin: 0.4rem 0; }

*:target { background: yellow; }

.web {
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

hr {
  text-align: center;
  border: 0;

  &:before { content: '/////' }
  &:after { content: attr(data-content) '/////' }
}

table { width: 100%; }

table, th, td {
  border: thin solid black;
  border-collapse: collapse;
  padding: 0.4rem;
}

code {
  color: white;
  background: black;
}

div.highlighter-rouge code {
  display: block;
  overflow-x: auto;
  white-space: pre-wrap;
  padding: 1rem;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1rem;

  p { margin: 0; }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
